import { mapMutations, mapState } from 'vuex';
import { getItem } from '@/api/api-methods';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import KnLocalAlert from '../../shared/components/KnLocalAlert.vue';
import KnTable from '../../shared/components/KnTable.vue';
import {
  canAdd,
  canChange,
  insufficientPermissionsMessage,
} from '../../shared/helpers/permissionsUtils';
export default {
  components: { KnLocalAlert, KnTable },
  mixins: [paginationMixin],
  data() {
    return {
      headers: [
        { text: 'Fecha', value: 'fecha', class: 'purple--text' },
        { text: 'Estatus', value: 'estatus.dato', class: 'purple--text' },
        { text: 'Concepto', value: 'concepto', class: 'purple--text' },
        { text: 'Subtotal', value: 'subtotal', class: 'purple--text' },
        { text: 'Descuento', value: 'descuento', class: 'purple--text' },
        { text: 'Total', value: 'total', class: 'purple--text' },
        { text: '', value: 'acciones' },
      ],
      items: [],
      expenses: [],
      loading: false,
      itemToEdit: null,
      loadingDownload: false,

      /** Variables para alerta */
      errors: [],
      warnings: [],
      loadingAlert: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Estas a punto de "Corregir" un ingreso. Deseas continuar?',
      alertColor: 'error',
      /*********************** */
    };
  },
  computed: {
    ...mapState(['institutionId', 'search']),
  },
  async created() {
    this.setIsLogin(false);
    await this.getExpenses();
  },
  watch: {
    search: {
      async handler() {
        if (this.loading) return;
        await this.delay(500);
        await this.getExpenses();
      },
    },
    institutionId: {
      async handler() {
        if (this.loading) return;
        await this.delay(500);
        await this.getExpenses();
      },
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    canAdd: canAdd,
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async getExpenses() {
      this.loading = true;
      try {
        const limitFilter =
          this.pagination.limit !== null
            ? `&limit=${this.pagination.limit}`
            : '';
        const offsetFilter =
          this.pagination.offset !== 0
            ? `&offset=${this.pagination.offset}`
            : '';
        const searchFilter =
          this.search !== null ? `&categoria=${this.search}` : '';

        const responseData = await getItem(
          `/app-administracion/filters/egreso?institucion_educativa=${this.institutionId}${limitFilter}${offsetFilter}${searchFilter}`
        );
        this.setPaginationCount(responseData.count);

        this.expenses = responseData.results.map((expense) => ({
          folio: expense.id,
          fecha: expense.fecha_elaboracion.substring(0, 10),
          concepto: `${expense.categoria.dato} - ${expense.sub_categoria.dato}`,
          subtotal: expense.sub_total,
          descuento: expense.total_descuento,
          total: expense.total_egreso,
          ...expense,
        }));
      } catch (error) {
        console.error('Error al obtener egresos:', error);
      } finally {
        this.loading = false;
      }
    },
    async changePageExpenses(page = 1) {
      this.setPaginationPage(page);
      await this.getExpenses();
    },
    openAlert(obj) {
      // console.log('Open modal', obj);
      // this.itemToEdit = this.expenses.find((i) => i.id === obj.itemId);
      // this.showAlert = true;

      if (canChange('egreso')) {
        this.alertType = 'success';
        this.alertText =
          'Estas a punto de "Corregir" un egreso. Deseas continuar?';
        this.alertColor = 'error';
        this.itemToEdit = this.expenses.find((i) => i.id === obj.itemId);
      } else {
        this.alertType = 'info';
        this.alertText = insufficientPermissionsMessage();
        this.alertColor = 'warning';
      }
      this.showAlert = true;
    },
    actionAlertBtn1() {
      this.closeAlert();
    },
    actionAlertBtn2() {
      this.showAlert = false;
      this.$router.push({
        name: 'Editar Egresos',
        params: {
          entity: this.itemToEdit,
          readonly: false,
        },
      });
    },
    closeAlert() {
      this.itemToEdit = null;
      this.showAlert = false;
    },
  },
};
